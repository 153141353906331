import { motion } from "framer-motion";
import React from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";

const Contact = styled.a`
  color: ${(props) => props.theme.text};
  position: absolute;
  top: 2rem;
  right: calc(1rem + 2vw);
  text-decoration: none;
  z-index: 1;
`;

const BLOG = styled(NavLink)`
  color: ${(props) => props.theme.text};
  position: absolute;
  top: 50%;
  right: calc(1rem + 2vw);
  transform: rotate(90deg) translate(-50%, -50%);
  text-decoration: none;
  z-index: 1;
`;

const WORK = styled(NavLink)`
  color: ${(props) => (props.click ? props.theme.body : props.theme.text)};
  position: absolute;
  top: 50%;
  left: calc(1rem + 2vw);
  transform: translate(-50%, -50%) rotate(-90deg);
  text-decoration: none;
  z-index: 1;
`;

const BottomBar = styled.div`
  position: absolute;
  bottom: 1rem;
  left: 0;
  right: 0;
  width: 100%;
  display: flex;
  justify-content: space-evenly;
`;

const ABOUT = styled(NavLink)`
  color: ${(props) => (props.click ? props.theme.body : props.theme.text)};
  text-decoration: none;
  z-index: 1;
`;

const SKILLS = styled(NavLink)`
  color: ${(props) => props.theme.text};
  text-decoration: none;
  z-index: 1;
`;

const Nav = ({ click }) => {
  const motionProps = {
    initial: {
      y: -200,
      transition: { type: "spring", duration: 1.5, delay: 1 },
    },
    animate: {
      y: 0,
      transition: { type: "spring", duration: 1.5, delay: 1 },
    },
    whileHover: { scale: 1.1 },
    whileTap: { scale: 0.9 },
  };

  const bottomMotionProps = {
    ...motionProps,
    initial: { ...motionProps.initial, y: 200 },
  };

  return (
    <>
      <Contact target="_blank" href="mailto:osisegabriel@gmail.com">
        <motion.h2 {...motionProps}>Say hi..</motion.h2>
      </Contact>

      <BLOG to="/blog">
        <motion.h2 {...motionProps}>Blog</motion.h2>
      </BLOG>

      <WORK to="/work" click={+click}>
        <motion.h2 {...motionProps}>Work</motion.h2>
      </WORK>

      <BottomBar>
        <ABOUT to="/about" click={+click}>
          <motion.h2 {...bottomMotionProps}>About.</motion.h2>
        </ABOUT>
        <SKILLS to="/skills">
          <motion.h2 {...bottomMotionProps}>My Skills.</motion.h2>
        </SKILLS>
      </BottomBar>
    </>
  );
};

export default Nav;
