// work data
import museskyImg from "../assets/Images/museskyBg.png";
import buzzyImg from "../assets/Images/buzzyBg.png";
import gmfiImg from "../assets/Images/gmfiBg.png";
import ghsImg from "../assets/Images/ghsBg.png";

export const Work = [
  {
    id: 1,
    name: "MuseSky NFT Marketplace",
    bg: museskyImg,
    description: "A marketplace for NFTs built on the ICP blockchain",
    tags: ["react", "Tailwind", "framer-motion", "rust", "web3"],
    demo: "https://4zf25-paaaa-aaaao-qex7q-cai.icp0.io/",
    github: "https://github.com/ELDEVODE/MuseSky",
  },
  {
    id: 2,
    name: "Buzzy.ng",
    bg: buzzyImg,
    description:
      "A social media marketing platform where users earn rewards by sharing ads and completing promotional tasks",
    tags: ["react", "materialUI", "web sockets", "nodejs", "express"],
    demo: "https://buzzy.ng/",
    github: "https://github.com/Solutech11/Buzzy-frontend-",
  },
  {
    id: 3,
    name: "GMFI Cyberquest",
    bg: gmfiImg,
    description:
      "Arcade style quiz game built on SUI blockchain, with react, and AI integration",
    tags: ["react", "AI", "express", "SUI", "web3"],
    demo: "https://gmfi.netlify.app/",
    github: "https://github.com/Geek-abyte/GMFI_frontend",
  },
  {
    id: 4,
    name: "Global Health Solutions",
    bg: ghsImg,
    description:
      "An Application built to provide quick health care consultation and services by connecting patients to doctors",
    tags: ["react", "web-sockets", "mongoDB", "nodejs", "express", "Python"],
    demo: "https://react-sidebar.vercel.app/",
    github: "https://github.com/geek-abyte",
  },
];
