import React from "react";
import { ThemeProvider } from "styled-components";
import { DarkTheme } from "../Themes";
import { Box, Spaceman, Main } from "./AboutPage.styles";

import LogoComponent from "../../subComponents/LogoComponent";
import SocialIcons from "../../subComponents/SocialIcons";
import PowerButton from "../../subComponents/PowerButton";
import ParticleComponent from "../../subComponents/ParticleComponent";
import BigTitle from "../../subComponents/BigTitle";
import astronaut from "../../assets/Images/spaceman.png";

const AboutPage = () => {
  return (
    <ThemeProvider theme={DarkTheme}>
      <Box>
        <LogoComponent theme="dark" />
        <SocialIcons theme="dark" />
        <PowerButton />
        <ParticleComponent theme="dark" />

        <Spaceman>
          <img src={astronaut} alt="spaceman" />
        </Spaceman>
        <Main>
          Developer for fun and profit 😉
          <br />
          <br />
          I'm a full-stack web developer located in Nigeria. <br />
          I like to build exciting and functional web applications and love the
          ability that programming gives me to bring new and stunning ideas to
          life. <br />
          I like learning about new technologies and am currently facinated and
          learning about web3 and blockchain. <br />I look forward to seeing
          what challenges and interesting work the future will bring me.
        </Main>

        <BigTitle text="ABOUT" top="10%" left="5%" />
      </Box>
    </ThemeProvider>
  );
};

export default AboutPage;
