import styled from "styled-components";
import { motion } from "framer-motion";

export const Box = styled.div`
  position: relative;
  height: 280vh;
  display: flex;
  align-items: center;
  background: ${(props) => props.theme.body};
  transition: background 0.8s ease-in-out;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: ${(props) =>
      props.bgImage
        ? `
      linear-gradient(
        rgba(0, 0, 0, 0.85),
        rgba(0, 0, 0, 0.85)
      ),
      url(${props.bgImage})
    `
        : "transparent"};
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    opacity: ${(props) => (props.bgImage ? 1 : 0)};
    transition: opacity 0.8s ease-in-out;
    z-index: 0;
  }

  // & > * {
  //   position: relative;
  //   z-index: 1;
  // }

  @media (max-width: 768px) {
    height: 350vh;
  }
`;

export const Main = styled(motion.ul)`
  position: fixed;
  top: 12rem;
  left: calc(10rem + 15vw);
  height: 40vh;
  display: flex;
  color: white;
`;

export const Rotate = styled.span`
  display: block;
  position: fixed;
  right: 1rem;
  bottom: 1rem;
  width: 80px;
  height: 80px;
  z-index: 1;
`;

export const container = {
  hidden: { opacity: 0 },
  show: {
    opacity: 1,
    transition: {
      staggerChildren: 0.5,
      duration: 0.5,
    },
  },
};
