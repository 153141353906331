import styled from "styled-components";

export const Box = styled.div`
  background-color: ${(props) => props.theme.body};
  width: 100vw;
  height: 100vh;
  position: relative;
  display: flex;
  justify-content: space-evenly;
  align-items: center;

  @media (max-width: 768px) {
    flex-direction: column;
    justify-content: center;
  }
`;

export const Main = styled.div`
  border: 2px solid ${(props) => props.theme.text};
  color: ${(props) => props.theme.text};
  background-color: ${(props) => props.theme.body};
  padding: 2rem;
  width: 30vw;
  height: 60vh;
  z-index: 3;
  line-height: 1.5;
  cursor: pointer;
  font-family: "Ubuntu Mono", monospace;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &:hover {
    color: ${(props) => props.theme.body};
    background-color: ${(props) => props.theme.text};
  }

  @media (max-width: 768px) {
    width: auto;
    height: auto;
    margin-left: 5rem; // Added asymmetric margin
    margin-right: 1rem;
    opacity: ${(props) => (props.view === props.activeView ? 1 : 0)};
    display: ${(props) => (props.view === props.activeView ? "flex" : "none")};
    transition: opacity 0.3s ease;

    /* Override hover styles and use activeView for color scheme in mobile */
    &:hover {
      color: inherit;
      background-color: inherit;
    }

    color: ${(props) =>
      props.view === props.activeView ? props.theme.body : props.theme.text};
    background-color: ${(props) =>
      props.view === props.activeView ? props.theme.text : props.theme.body};
  }
`;

export const Title = styled.h2`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: calc(1em + 1vw);

  ${Main}:hover & {
    & > * {
      fill: ${(props) => props.theme.body};
    }
  }

  & > *:first-child {
    margin-right: 1rem;
  }
`;

export const Description = styled.div`
  color: ${(props) => props.theme.text};
  font-size: calc(0.6em + 1vw);
  padding: 0.5rem 0;

  ${Main}:hover & {
    color: ${(props) => props.theme.body};
  }

  @media (max-width: 768px) {
    ${Main}:hover & {
      color: inherit;
    }

    color: ${(props) =>
      props.view === props.activeView ? props.theme.body : props.theme.text};
  }

  strong {
    margin-bottom: 1rem;
    text-transform: uppercase;
  }
  ul,
  p {
    margin-left: 2rem;
  }
`;

export const MobileControls = styled.div`
  display: none;
  @media (max-width: 768px) {
    position: relative;
    left: 4rem;
    display: flex;
    gap: 10px;
  }
`;

export const TabButton = styled.button`
  padding: 8px 16px;
  background: ${(props) =>
    props.view === props.activeView ? props.theme.text : props.theme.body};
  color: ${(props) =>
    props.view === props.activeView ? props.theme.body : props.theme.text};
  border: 1px solid ${(props) => props.theme.text};
  border-bottom: none;
  border-radius: 0; // Removed rounded corners
  cursor: pointer;
  font-family: "Ubuntu Mono", monospace;
`;
