import React, { useState, useRef, useEffect } from "react";
import { ThemeProvider } from "styled-components";
import { DarkTheme } from "../Themes";
import LogoComponent from "../../subComponents/LogoComponent";
import SocialIcons from "../../subComponents/SocialIcons";
import PowerButton from "../../subComponents/PowerButton";
import { Work } from "../../data/WorkData";
import Card from "../../subComponents/Card";
import { YinYang } from "../AllSvgs";
import BigTitlte from "../../subComponents/BigTitle";
import { Box, Main, Rotate, container } from "./WorkPage.styles";

const WorkPage = () => {
  const [bgImage, setBgImage] = useState(null);
  const ref = useRef(null);
  const yinyang = useRef(null);

  useEffect(() => {
    let element = ref.current;

    const rotate = () => {
      if (element) {
        element.style.transform = `translateX(${-window.scrollY}px)`;
      }
      if (yinyang.current) {
        yinyang.current.style.transform = `rotate(${-window.scrollY}deg)`;
      }
    };

    window.addEventListener("scroll", rotate);
    return () => window.removeEventListener("scroll", rotate);
  }, []);

  return (
    <ThemeProvider theme={DarkTheme}>
      <Box bgImage={bgImage}>
        <LogoComponent theme="dark" />
        <SocialIcons theme="dark" />
        <PowerButton />

        <Main ref={ref} variants={container} initial="hidden" animate="show">
          {Work.map((d) => (
            <Card
              key={d.id}
              data={d}
              onMouseEnter={() => setBgImage(d.bg)}
              onMouseLeave={() => setBgImage(null)}
            />
          ))}
        </Main>
        <Rotate ref={yinyang}>
          <YinYang width={80} height={80} fill={DarkTheme.text} />
        </Rotate>

        <BigTitlte text="WORK" top="10%" right="20%" />
      </Box>
    </ThemeProvider>
  );
};

export default WorkPage;
