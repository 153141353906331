import React, { useState } from "react";
import { ThemeProvider } from "styled-components";
import { lightTheme } from "../Themes";
import { Design, Develope } from "../AllSvgs";
import LogoComponent from "../../subComponents/LogoComponent";
import SocialIcons from "../../subComponents/SocialIcons";
import PowerButton from "../../subComponents/PowerButton";
import ParticleComponent from "../../subComponents/ParticleComponent";
import BigTitle from "../../subComponents/BigTitle";
import {
  Box,
  Main,
  Title,
  Description,
  MobileControls,
  TabButton,
} from "./MySkillsPage.styles";

const MySkillsPage = () => {
  const [activeView, setActiveView] = useState("frontend");

  return (
    <ThemeProvider theme={lightTheme}>
      <Box>
        <LogoComponent theme="light" />
        <SocialIcons theme="light" />
        <PowerButton />
        <ParticleComponent theme="light" />

        <MobileControls>
          <TabButton
            view="frontend"
            activeView={activeView}
            onClick={() => setActiveView("frontend")}
          >
            Frontend
          </TabButton>
          <TabButton
            view="backend"
            activeView={activeView}
            onClick={() => setActiveView("backend")}
          >
            Backend
          </TabButton>
        </MobileControls>

        <Main view="frontend" activeView={activeView}>
          <Title>
            <Design width={40} height={40} /> Frontend Developer
          </Title>
          <Description>
            Crafting visually appealing and user-friendly interfaces.
          </Description>
          <Description>
            <strong>Skills</strong>
            <p>
              Html, Css, Javascript, React, React Native, NextJs, ReactNative
              etc.
            </p>
          </Description>
          <Description>
            <strong>Tools</strong>
            <ul>
              <li>Figma</li>
            </ul>
          </Description>
        </Main>

        <Main view="backend" activeView={activeView}>
          <Title>
            <Develope width={40} height={40} /> Backend Developer
          </Title>
          <Description>
            Building robust and scalable server-side applications.
          </Description>
          <Description>
            <strong>Skills</strong>
            <p>
              NodeJs, Python, Rust, MongoDB, Sql, Graphql, Restful APIs etc.
            </p>
          </Description>
          <Description>
            <strong>Tools</strong>
            <p>Github, Docker etc.</p>
          </Description>
        </Main>

        <BigTitle text="SKILLS" top="80%" right="30%" />
      </Box>
    </ThemeProvider>
  );
};

export default MySkillsPage;
